import { parseCookies } from "nookies";

const cookies = parseCookies();

const BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://app.agromai.com.br/api/v1";

// Function to handle fetch requests with error handling
async function handleFetch(url, options = {}, timeout = 100000) {
  let headers = {};

  if (options.headers && options.headers.hasOwnProperty('Authorization')) {
    headers = {
      ...options.headers,
    };
  } else {
    const cookies = parseCookies();

    headers = {
      Authorization: `Token ${cookies["auth-token"]}`,
      ...options.headers,
    };
  }

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      headers: headers,
      signal: controller.signal,
      ...options,
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(`HTTP error`);
      error.code = response.status;
      error.responseData = responseData;
      throw error;
    }

    return responseData;
  } catch (error) {
    if (error.name === "AbortError") {
      const timeoutError = new Error("Request timed out");
      timeoutError.code = "TIMEOUT";
      throw timeoutError;
    }
    throw error;
  } finally {
    clearTimeout(timeoutId);
  }
}

// Function to validate KML file
// export async function validateKMLFile(file) {
//   const formData = new FormData();
//   formData.append("file", file);

//   return handleFetch(`${BASE_URL}/validate-kml/`, {
//     method: "POST",
//     body: formData,
//   });
// }

export async function bulkSubmitAnalysis(formDataList) {
  const formDataToSend = new FormData();
  const farms = [];
  formDataList.forEach((formData) => {
    formDataToSend.append("kml_document", formData.file);
    let auraStatus;
    let senseStatus;
    if (formData.serviceType === "aura") {
      auraStatus = true;
      senseStatus = false;
    } else if (formData.serviceType === "sense") {
      auraStatus = false;
      senseStatus = true;
    }
    const farm = {
      farm_name: formData.farmName,
      field_name: formData.fieldName,
      cycle_type: parseInt(formData.cycleType),
      soil_type: null,
      soil_type_ad: null,
      created_by: cookies["user-id"],
      proposal_id: formData.proposalId,
      crop_type: formData.cropType,
      ticket_parameters: {
        consultation_status: "P",
        aura_status: auraStatus,
        sense_status: senseStatus,
      },
    };

    farm.soil_type_ad = parseInt(formData.soilType);


    farms.push(farm);
  });
  const farmData = { farmer: formDataList[0]?.producerName, farms: farms };

  formDataToSend.append("farm_data", JSON.stringify(farmData));
  // Determine consultation_status based on serviceType
  return handleFetch(`${BASE_URL}/consultation-tickets/`, {
    method: "POST",
    body: formDataToSend,
  });
}

// Function to send kmls for processing + ticket generation
// export async function submitAnalysis(formData) {
//   const formDataToSend = new FormData();
//   formDataToSend.append("kml_document", formData.file);
//
//   // Determine consultation_status based on serviceType
//   let auraStatus;
//   let senseStatus;
//   if (formData.serviceType === "Aura") {
//     auraStatus = true;
//     senseStatus = false;
//   } else if (formData.serviceType === "Sense") {
//     auraStatus = false;
//     senseStatus = true;
//   }
//
//   const farmData = {
//     farmer: formData.producerName,
//     farms: [
//       {
//         farm_name: formData.farmName,
//         field_name: formData.fieldName,
//         cycle_type: parseInt(formData.cycleType),
//         soil_type: parseInt(formData.soilType),
//         created_by: cookies["user-id"],
//         proposal_id: formData.proposalId,
//         crop_type: formData.cropType,
//         ticket_parameters: {
//           consultation_status: "P",
//           aura_status: auraStatus,
//           sense_status: senseStatus,
//         },
//       },
//     ],
//   };
//
//   // Log the farmData to the console before appending it to formDataToSend
//   formDataToSend.append("farm_data", JSON.stringify(farmData));
//
//   return handleFetch(`${BASE_URL}/consultation-tickets/`, {
//     method: "POST",
//     body: formDataToSend,
//   });
// }

// Weather Data Endpoints
export async function fetchMonthlyWeatherData(KMLID) {
  return handleFetch(
    `${BASE_URL}/weather-data/get_monthly_data?ticket_id=${KMLID}`
  );
}

export async function fetchRiskScore(KMLID) {
  return handleFetch(
    `${BASE_URL}/consultation-tickets/${KMLID}/get_risk_score`
  );
}

export async function updateTicketStatus(ticket, newStatus) {
  const cookies = parseCookies();

  if (ticket.hasOwnProperty('farm_data')) {
    return handleFetch(`${BASE_URL}/consultation-tickets/${ticket.id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${cookies["auth-token"]}`,
      },
      body: JSON.stringify({
        id: ticket.id,
        farm_data: ticket.farm_data.id,
        consultation_status: newStatus,
        risk_score: ticket.risk_score,
        ticket_priority: ticket.ticket_priority,
        sense_status: ticket.sense_status,
        aura_status: ticket.aura_status,
      }),
    });
  } else {
    return handleFetch(`${BASE_URL}/consultation-tickets/${ticket.id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${cookies["auth-token"]}`,
      },
      body: JSON.stringify({
        id: ticket.id,
        farm_data: Number(ticket.farm_id),
        consultation_status: newStatus,
        risk_score: ticket.risk_score,
        ticket_priority: ticket.ticket_priority,
        sense_status: ticket.sense_status,
        aura_status: ticket.aura_status,
      }),
    });
  }
}

export async function sendTicketToSense(ticket) {
  const cookies = parseCookies();

  if (ticket.hasOwnProperty('farm_data')) {
    return handleFetch(`${BASE_URL}/consultation-tickets/${ticket.id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${cookies["auth-token"]}`,
      },
      body: JSON.stringify({
        id: ticket.id,
        farm_data: ticket.farm_data.id,
        consultation_status: ticket.consultation_status,
        risk_score: ticket.risk_score,
        ticket_priority: ticket.ticket_priority,
        sense_status: true,
        aura_status: ticket.aura_status,
      }),
    });
  } else {
    return handleFetch(`${BASE_URL}/consultation-tickets/${ticket.id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${cookies["auth-token"]}`,
      },
      body: JSON.stringify({
        id: ticket.id,
        farm_data: Number(ticket.farm_id),
        consultation_status: ticket.consultation_status,
        risk_score: ticket.risk_score,
        ticket_priority: ticket.ticket_priority,
        sense_status: true,
        aura_status: ticket.aura_status,
      }),
    });
  }
}

export async function deleteUserTicket(KMLID) {
  return handleFetch(`${BASE_URL}/consultation-tickets/${KMLID}/`, {
    method: "DELETE",
  });
}

export async function deleteUserTicketFromListing(KMLID) {
  return handleFetch(`${BASE_URL}/monitoring-reports/${KMLID}/`, {
    method: "DELETE",
  });
}

export async function fetchTicketData(KMLID) {
  return handleFetch(`${BASE_URL}/consultation-tickets/${KMLID}`);
}

export async function fetchAuraTickets(
  page = 1,
  searchString = "",
  consultationStatus = [],
  order_by = "",
) {
  let searchParameters = "";

  if (searchString) {
    searchParameters += `&search_string=${searchString}`;
  }

  if (consultationStatus) {
    const statusString = consultationStatus.join(',');
    searchParameters += `&consultation_status=${statusString}`;
  }

  if (order_by) {
    searchParameters += `&order_by=${order_by}`;
  }

  return handleFetch(
    `${BASE_URL}/consultation-tickets?page=${page}&aura_status=true${searchParameters}`
  );
}

export async function fetchSenseTickets(
  page = 1,
  searchString = "",
  consultationStatus = [],
  order_by = "",
) {
  let searchParameters = "";

  if (searchString) {
    searchParameters += `&search_string=${searchString}`;
  }

  if (consultationStatus) {
    const statusString = consultationStatus.join(',');
    searchParameters += `&consultation_status=${statusString}`;
  }

  if (order_by) {
    searchParameters += `&order_by=${order_by}`;
  }

  return handleFetch(
    `${BASE_URL}/consultation-tickets?page=${page}&sense_status=true${searchParameters}`
  );
}

export async function fetchSensePlusTickets(page = 1) {
  return handleFetch(
    `${BASE_URL}/sense-plus?page=${page}`
  );
}

export async function fetchSenseTicketData(KMLID) {
  return handleFetch(
    `${BASE_URL}/monitoring-reports/latest-report/?ticket_id=${KMLID}`
  );
}

export async function fetchSenseDatedReports(KMLID) {
  return handleFetch(`${BASE_URL}/monitoring-reports?ticket_id=${KMLID}`);
}

// Visualization Endpoints
export async function fetchMapContext(report_type, ticket_id) {
  const requestData = {
    start_date: "2023-10-09",
    report_type,
    ticket_id,
    rows: 70,
    cols: 70,
  };

  const cookies = parseCookies();
  return handleFetch(`${BASE_URL}/data-reports/generate-map-context/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${cookies["auth-token"]}`,
    },
    body: JSON.stringify(requestData),
  });
}

export async function fetchNDVI(
  ticket_id,
  report_type,
  years,
  report_periodicity,
  weather_periodicity
) {
  const requestData = {
    ticket_id,
    report_type,
    years,
    report_periodicity,
    weather_periodicity,
  };
  const cookies = parseCookies();
  return await handleFetch(
    `${BASE_URL}/data-reports/collective_data/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${cookies["auth-token"]}`,
      },
      body: JSON.stringify(requestData),
    }
  );
}

// Map Endpoints
export async function fetchMap(KMLID) {
  return handleFetch(`${BASE_URL}/consultation-tickets/${KMLID}/get_map_html`);
}

export async function loadNotionContent(pageId) {
  const response = await fetch(
    `https://notion-api.splitbee.io/v1/page/${pageId}`
  );
  const data = await response.json();
  return data;
}

// Vision endpoints
export async function fetchVisionData(KMLID) {
  return handleFetch(`${BASE_URL}/vision/${KMLID}`);
}

// Billing endpoints
export async function fetchBillingReport() {
  return handleFetch(`${BASE_URL}/billing-report`);
}

export async function fetchBillingReportHistory(page = 1) {
  return handleFetch(`${BASE_URL}/billing-ticket-history?page=${page}`);
}

export async function fetchDataUser(url, data, method, options = {}) {
  const cookies = parseCookies();
  const controller = new AbortController();

  try {
    const response = await fetch(url, {
      method: method,
      body: data ? JSON.stringify(data) : null,
      headers: {
        ...options.headers,
        Authorization: `Token ${cookies["auth-token"]}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      signal: controller.signal,
      ...options,
    });
    const text = await response.text();
    return text ? JSON.parse(text) : {};
  } catch (error) {
    console.error(`Erro ao enviar requisição POST para ${url}:`, error);
    throw error;
  }
}

export async function fetchUsers(page = 1) {
  return handleFetch(`${BASE_URL}/users?page=${page}`);
}

export async function createUser(userData) {
  try {
    const response = await fetchDataUser(
      `${BASE_URL}/users/`,
      userData,
      "POST"
    );
    return response;
  } catch (error) {
    console.error("Erro ao criar usuário:", error);
    throw error;
  }
}

export async function updateUser(userData) {
  try {
    const { id, ...data } = userData;

    const response = await fetchDataUser(
      `${BASE_URL}/users/${id}/`,
      data,
      "PUT"
    );
    return response;
  } catch (error) {
    console.error("Erro ao criar usuário:", error);
    throw error;
  }
}

export async function deleteUser(id) {
  try {
    const response = await fetchDataUser(
      `${BASE_URL}/users/${id}/delete`,
      null,
      "DELETE"
    );
    return response;
  } catch (error) {
    console.error("Erro ao criar usuário:", error);
    throw error;
  }
}
