import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.pattern"; // Ensure leaflet.pattern is installed and properly imported
import VisionErrorPopover from "./VisionErrorPopover";
import { polygonStylePatterns } from "./polyStyles";
import "./Vision.css"
import { appToEsgId } from "./AppToEsgId";

// Dictionary to map id_esg to human-readable names
const esgNameDictionary = {
  1: "Área de Preservação Permanente",
  5: "Área do Imóvel - SNCI",
  6: "Área de Pousio",
  7: "Área de Topo de Morro",
  8: "Banhado",
  9: "Borda de Chapada",
  10: "Hidrografia",
  11: "Manguezal",
  12: "Nascente/Olho d'Água",
  13: "Reserva Legal",
  14: "Restinga",
  15: "Servidão Administrativa",
  16: "Uso Restrito",
  17: "Vegetação Nativa",
  18: "Vereda",
  19: "Desmatamento Prodes",
  20: "ICMBio",
  21: "IBAMA",
  22: "Assentamento",
};

const VisionMapTwo = ({ data }) => {
  const [visibleLayers, setVisibleLayers] = useState({
    areaImovel: true,
    esgDetections: {},
  });

  useEffect(() => {
    if (data?.esg_detections?.features) {
      const initialEsgDetections = data.esg_detections.features.reduce((acc, feature) => {
        const app = feature.properties.app;
        const esgId = appToEsgId[app];
        acc[esgId] = true;
        return acc;
      }, {});
  
      setVisibleLayers((prev) => ({
        ...prev,
        esgDetections: initialEsgDetections,
      }));
    }
  }, [data, appToEsgId]);  

  const toggleLayerVisibility = (layerType, id) => {
    setVisibleLayers((prev) => {
      if (layerType === "areaImovel") {
        return { ...prev, areaImovel: !prev.areaImovel };
      } else if (layerType === "esgDetections") {
        return {
          ...prev,
          esgDetections: {
            ...prev.esgDetections,
            [id]: !prev.esgDetections[id],
          },
        };
      }
      return prev;
    });
  };

  const UpdateLayersOrder = () => {
    const map = useMap();

    useEffect(() => {
      if (map) {
        if (visibleLayers.areaImovel) {
          const carAreaLayer = map.eachLayer((layer) => {
            if (layer.options && layer.options.style && layer.options.style === polygonStylePatterns["carArea"]) {
              layer.bringToBack();
            }
          });
        }
      }
    }, [visibleLayers, map]);

    return null;
  };

  return (
    <div
      className="visionReportElementCard"
      style={{
        height: "768px",
        position: "relative",
        borderRadius: "8px",
      }}
    >
      <div className="visionMapLegendCard">
        <div className="visionMapLegendTitle">Legenda</div>
        <div className="visionMapLegendContent">
          {data?.esg_detections?.features.map((feature, index) => {
            const app = feature.properties.app;
            const id = appToEsgId[app];
            return (
              <div
                key={index}
                className="visionMapLegendLine"
                onClick={() => toggleLayerVisibility("esgDetections", id)}
              >
                <div
                  className="visionMapLegendSquare"
                  style={{
                    backgroundColor: `${polygonStylePatterns[id]?.color || "#999999"}`,
                    opacity: visibleLayers.esgDetections[id] ? 1 : 0.5,
                    cursor: "pointer",
                  }}
                ></div>
                <div className="visionLegendText">{esgNameDictionary[id] ?? `ID ${id}`}</div>
              </div>
            );
          })}
          <div
            className="visionMapLegendLine"
            onClick={() => toggleLayerVisibility("areaImovel")}
          >
            <div
              className="visionMapLegendSquare"
              style={{
                backgroundColor: "#1890FF",
                opacity: visibleLayers.areaImovel ? 1 : 0.5,
                cursor: "pointer",
              }}
            ></div>
            <div className="visionLegendText">Área do CAR</div>
          </div>
        </div>
      </div>
      <div style={{ height: "100%", width: "100%", padding: "4px" }}>
        <MapContainer
          center={[
            data?.center_coords?.lat || -14.235,
            data?.center_coords?.long || -51.9253,
          ]}
          zoom={data ? 15 : 5}
          maxZoom={16}
          zoomControl={false}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          {visibleLayers.areaImovel &&
            data?.area_imovel &&
            data.area_imovel.features.map((feature, index) => (
              <GeoJSON
                key={index}
                data={feature}
                style={polygonStylePatterns["carArea"]}
              />
            ))}
          {data?.esg_detections &&
            data.esg_detections.features.map((feature, index) => {
              const app = feature.properties.app;
              const idEsg = appToEsgId[app];
              
              return visibleLayers.esgDetections[idEsg] !== false && (
                <GeoJSON
                  key={index}
                  data={feature}
                  style={polygonStylePatterns[idEsg]}
                />
              );
            })
          }
          <UpdateLayersOrder />
        </MapContainer>
        {data?.status === "area_imovel_not_found" && <VisionErrorPopover />}
      </div>
    </div>
  );
};

export default VisionMapTwo;
